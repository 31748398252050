import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../components/layout'





import ecommerce from '../assets/images/ecommerce/ecommerce.png'
// import call from '../assets/images/ecommerce/call.png'

 

import hazy from '../assets/images/ecommerce/hazy.png'
import easy from '../assets/images/ecommerce/easy.png'
import sooncard from '../assets/images/ecommerce/sooncard.png'
import maria from '../assets/images/ecommerce/maria.png'
import deeva from '../assets/images/ecommerce/deeva.png'
import yyz from '../assets/images/ecommerce/yyz.png'
import circle from '../assets/images/ecommerce/circle.png'
import handmade from '../assets/images/ecommerce/handmade.png'
import xf from '../assets/images/ecommerce/xf.png'
import realstate from '../assets/images/ecommerce/realstate.png'
import wawa from '../assets/images/ecommerce/wawa.png'
import pinky from '../assets/images/ecommerce/pinky.png'
// import art from '../assets/images/ecommerce/art.png'
import relaxation from '../assets/images/ecommerce/relaxation.png'
import lavernesite from '../assets/images/ecommerce/lavernesite.png'








const Ecommerce = () => (
    <Layout>
        <Helmet>
            <title>eCommerce Websites</title>
            <meta name="description" content="Ecommerce Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>eCommerce Stores</h1>
                    </header>
                    <span className="image main"><img src={ecommerce} alt="ecommerce img" /></span>
                    <p>The advantages of establishing your business in the digital environment are numerous, so if you don't already have an online store, it's time to think more about it.</p>
                    <p>Our society is more and more accustomed to buying over the internet, both for convenience and practicality, which is why e-commerce is on the rise in the market today.</p>

                    <p>If you sell a product or service, it is high time to adapt to that medium.</p>

                    
                </div>
                
            </section>

            <section>

            <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${easy})`}}>
                            <header className="major">
                                <h3>Beauty</h3>
                            </header>
                            <Link to="https://easy-extensions.myshopify.com" className="link primary" target="_blank" rel="noopener noreferrer"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${hazy})`}}>
                            <header className="major">
                                <h3>Cards</h3>
                            </header>
                            <Link to="https://hazyjean.ca " className="link primary" target="_blank" rel="noopener noreferrer"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${ deeva})`}}>
                            <header className="major">
                                <h3>Digital Products</h3>

                            </header>
                            <Link to="https://deevadesignstudio.square.site" className="link primary" target="_blank" rel="noopener noreferrer"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${maria})`}}>
                            <header className="major">
                                <h3>Practitioner </h3>          
                            </header>
    
                            <Link to="https://mariagracepractitioner.square.site  " className="link primary" target="_blank" rel="noopener noreferrer"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${circle})`}}>
                            <header className="major">
                            <h3>Travel Agency</h3>    
                            </header>
                            <Link to="https://circlelinetours.square.site" className="link primary" target="_blank" rel="noopener noreferrer"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${yyz})`}}>
                            <header className="major">
                            <h3>Jewellery</h3>
                            </header>
                            <Link to="https://yyzbling.ca " className="link primary" target="_blank" rel="noopener noreferrer"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${handmade})`}}>
                            <header className="major">
                                <h3>Handmade Products</h3>
                            </header>
                            <Link to="https://handmadebysahiba.square.site" className="link primary" target="_blank" rel="noopener noreferrer"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${relaxation})`}}>
                            <header className="major">
                            <h3>Bath &amp; Body</h3>
                            </header>
                            <Link to="https://relaxationinspired.square.site " className="link primary" target="_blank" rel="noopener noreferrer"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${lavernesite})`}}>
                            <header className="major">
                                <h3>Findings</h3>
                            </header>
                            <Link to="https://www.lavsfantasticfinds.ca" className="link primary" target="_blank" rel="noopener noreferrer"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${realstate})`}}>
                            <header className="major">
                                <h3>Real State Photography</h3>
                            </header>
                            <Link to="https://aperturephotoservices.square.site" className="link primary" target="_blank" rel="noopener noreferrer"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pinky})`}}>
                            <header className="major">
                                <h3>Pinky Store</h3>
                            </header>
                            <Link to="https://pinkystore.ca/" className="link primary" target="_blank" rel="noopener noreferrer"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${xf})`}}>
                            <header className="major">
                            <h3>Print &amp; Marketing</h3>
                            </header>
                            <Link to="https://xfprint.square.site/" className="link primary" target="_blank" rel="noopener noreferrer"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${wawa})`}}>
                            <header className="major">
                            <h3>Rental Store</h3>
                            </header>
                            <Link to="https://wawa-rent-all-repair-inc.square.site" className="link primary" target="_blank" rel="noopener noreferrer"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${sooncard})`}}>
                            <header className="major">
                                <h3>Check back soon!!</h3>
                                <p>Yoga &amp; Wellness , Health Store, Hair Salon, Services, Apparel Stores &amp; many more...</p>
                            </header>
                            <Link to="/ecommerce" className="link primary"></Link>
                        </article>
                        
                    </section>
                      
                </div>





            </section>

            {/* <section>
                <div className="n_row">
                    <div className="n_column"> 
                        <img src={paris} alt=""/> 
                            <div className="n_overlay">
                                <div className="n_text">Hello World</div>
                            </div>    
                     </div>
                
                </div>
                    
            </section> */}
                           
{/* 
                        <div className="sidebar">
                            <img src={paris} alt="" /> 
                        </div>  */}

                
               
        </div>


    </Layout>
)

export default Ecommerce
